import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaThumbsUp } from "react-icons/fa";
import { BiDownArrow } from "react-icons/bi";
import axios from "axios";

const ReviewListSection = styled.div`
  margin-top: 20px;
`;

const ReviewTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
`;

const ReviewText = styled.p`
  // margin: 10px 0;
`;
const ReviewText2 = styled.p`
  // border-bottom: 1px solid #ccc;
  color: grey;
  // margin: 10px 0;
`;

const ReviewSectionWrapper = styled.div`
  margin: 20px;
  padding: 20px;
`;

const ReviewList = styled.ul`
  list-style: none;
  padding: 0;
`;

const DIV = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
`;

const Name = styled.div`
  color: #007bff;
`;

const ReviewSection = () => {
  const [reviews, setReviews] = useState(null);
  const [filteredReviews, setFilteredReviews] = useState(null);
  const [num, setNum] = useState(5);

  useEffect(() => {
    const getReviews = async () => {
      const res = await axios.get(
        "https://api-ecommerce-bay.vercel.app/api/review/"
      );
      setReviews(res.data);
      setFilteredReviews(res.data.slice(0, num));
    };

    getReviews();
  }, []);

  const handleSeeMore = () => {
    const newNum = num + 5;
    setFilteredReviews(reviews.slice(0, newNum)); // Use slice() to create a new array
    setNum(newNum);
  };

  return (
    <ReviewSectionWrapper>
      <ReviewListSection>
        <ReviewTitle>All Reviews</ReviewTitle>
        <ReviewList>
          {filteredReviews &&
            filteredReviews.map((review, index) => (
              <DIV key={index}>
                <Name>
                  {review.name}{" "}
                  <span style={{ marginLeft: "5px", color: "grey" }}>
                    Verified Buyer
                  </span>
                </Name>

                <div>Rating: {review.stars} stars</div>
                <ReviewText>{review.title}</ReviewText>
                <ReviewText2>{review.review}</ReviewText2>
                {/* <div>
                <span style={{ cursor: "pointer" }}>
                  <FaThumbsUp />
                </span>
                <span style={{ paddingLeft: "8px" }}>{review.likes}</span>
              </div> */}
              </DIV>
            ))}
        </ReviewList>

        <div
          onClick={handleSeeMore}
          style={{
            textAlign: "center",
            margin: "1rem 0",
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          More Reviews <BiDownArrow />
        </div>
      </ReviewListSection>
    </ReviewSectionWrapper>
  );
};

export default ReviewSection;
