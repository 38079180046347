import React, { useEffect, useState } from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Container = styled.div``;

const DIVV = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Div = styled.div`
  min-height: 90vh;
`;

const Orders = () => {
  const navigate = useNavigate();
  const { currentUser, isFetching, error } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);

  console.log(currentUser);

  useEffect(() => {
    const getAllOrder = async () => {
      const res = await axios.get(
        `https://api-ecommerce-bay.vercel.app/api/orders/find/${currentUser._id}`
      );
      setAllOrders(res.data);
      console.log(res.data);
    };
    if (!currentUser) {
      navigate("/login");
    } else {
      getAllOrder();
    }
  }, []);

  return (
    <Container>
      <Navbar />
      <h1>Orders</h1>

      <Div>
        {allOrders ? (
          allOrders.map((order) => (
            <DIVV key={order._id}>
              <h2>Order ID: {order._id}</h2>
              <p>Amount: {order.amount}</p>
              <p>Created At: {order.createdAt}</p>
              <p>User Mobile: {order.userMobile}</p>
              <p>Products:</p>
              <ul>
                {order.products.map((product) => (
                  <li key={product._id}>
                    {product.productName} - Quantity: {product.quantity}
                  </li>
                ))}
              </ul>
              <hr />
            </DIVV>
          ))
        ) : (
          <div>No orders yet</div>
        )}
      </Div>

      <Footer />
    </Container>
  );
};

export default Orders;
