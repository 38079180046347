import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import React from "react";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Success from "./pages/Success";
import { useSelector } from "react-redux";
import Blogs from "./pages/Blogs";
import Consulation from "./pages/consultation";
import Blog from "./pages/BLog";
import Policy from "./pages/Policy";
import TermsConditions from "./pages/Terms";
import Refund from "./pages/Refund";
import ShippingDetails from "./pages/ShippingDetails";
import Contact from "./pages/Contact";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Register from "./pages/Register";
import Orders from "./pages/Orders";
// import Products from "./components/Products";

const App = () => {
  // const user = true;
  // const user = useSelector((state) => state.user.currentUser);
  // console.log(user);

  const [user, setUserData] = useState("");

  const { currentUser, isFetching, error } = useSelector((state) => state.user);

  useEffect(() => {
    console.log(user);
    setUserData(currentUser);
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products/:category" element={<ProductList />} />
        <Route path="/product/:id" element={<Product />} />
        
        <Route path="/orders" element={<Orders />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/consultation"
          element={!user ? <Navigate to="/login" /> : <Consulation />}
        />
        <Route
          path="/success"
          element={!user ? <Navigate to="/login" /> : <Success />}
        />
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        <Route
          path="/register"
          element={user ? <Navigate to="/" /> : <Register />}
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<Blog />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/shipping" element={<ShippingDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
