import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Pinterest,
  Room,
  Twitter,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column", backgroundColor: "#fff8f8" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0px;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile({ alignItems: "start", padding: "0 20px" })}
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-directoin: column;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ backgroundColor: "#fff8f8" })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Payment = styled.img`
  width: 50%;
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>Vishweshwara Rudraksha</Logo>
        <Desc>
          Rooted from the land of Shiva – Kashi , Vishweshwara Rudraksha traces
          its Linage back to a prestigious , long established parent firm –
          Himanshu Mala Store , founded nearly 60 years ago in Kashi , near Shri
          Kashi Vishwanath temple and thereby serves you with its best of
          expertise and right knowledge. At Vishweshwara Rudraksha we make
          sincere efforts to provide you fair pricing , primarily achieved
          through direct bulk purchases from farmers in Indonesia and Nepal. We
          ensure that every rudraksha comes with a certificate of authenticity.
          At vishweshwara Rudraksha quality is upheld as our each product is
          meticulously hand selected by our knowledgeable and experienced team
          to ensure the highest standards for you. In case of any issue feel
          free to contact us on whatsapp at +917007303737 or email us at
          vishweshwararudraksha.com ( Mon - Sat 10 am - 8 pm).{" "}
        </Desc>
        {/* <br />
        <br />
        <br /> */}
        {/* <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram />
          </SocialIcon>
          <SocialIcon color="55ACEE">
            <Twitter />
          </SocialIcon>
          <SocialIcon color="E60023">
            <Pinterest />
          </SocialIcon>
        </SocialContainer> */}
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <div>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              to="/policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              to="/terms"
            >
              Terms and Conditions
            </Link>
          </div>

          <div>
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              to="/refund"
            >
              Refund
            </Link>
          </div>

          <div>
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              to="/shipping"
            >
              Shipping Details
            </Link>
          </div>

          <div>
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              to="/contactus"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </Center>
      <Right>
        <Title>Contact Us</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} /> CK-36/6, GYANVAPI, VARANASI,
          UTTAR PRADESH, 221001
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> +91 7007303737
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} />
          vishweshwararudraksha@gmail.com
        </ContactItem>
      </Right>
    </Container>
  );
};

export default Footer;
