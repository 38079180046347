import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import FeaturedProductsCrousel from "../components/FeaturedProductsCrousel";
import { mobile } from "../responsive";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  text-align: center;
`;

const DIV = styled.div`
  display: flex;
  width: 100%;

  ${mobile({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  })}
`;

const DivIneer1 = styled.div`
  width: 70%;
`;

const DivIneer2 = styled.div`
  width: 28%;
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  // justify-content:center;
  padding: 10px;
  margin-right: 2%;

  ${mobile({ width: "70%", alignItems: "center", justifyContent: "center" })}
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const DivScroll = styled.div`
  height: 400px;
  overflow: scroll;
  display: flex;
  flex-drection: column;
  width: 100%;
`;

const Blog = () => {
  const location = useLocation();
  const reqId = location.pathname.split("/")[2];
  console.log(reqId);
  const [reqBlog, setRequiredBlog] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await axios.get(
          "https://api-ecommerce-bay.vercel.app/api/blog/"
        );
        const allBlogs = res.data;
        const blog = allBlogs.filter((blog) => blog._id === reqId);
        setRequiredBlog(blog[0]);
        console.log(blog[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBlog();
  }, [reqId]);

  return (
    <>
      <Container>
        <Navbar />
        <DIV>
          <DivIneer1
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              margin: "auto",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              style={{
                width: "100%",
                minHeight: "40vw",
                objectFit: "cover",
                borderRadius: "1rem",
              }}
              src={reqBlog.img}
              alt="img of blog"
            />
            <div>
              <h1>{reqBlog.title}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: reqBlog.data1 }}></div>
          </DivIneer1>
          {/* <DivIneer2>

                        <Title>
                            Recommended Articles
                        </Title>
                        <DivScroll>

                            <div style={{ padding: "8px", borderBottom: "1px solid", height: "20px" }}>
                                Properties of different mukhis
                            </div>
                        </DivScroll>


                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Title>
                                Watch Our Videos
                            </Title>

                            <div style={{ width: "100%" ,display: "flex", alignItems: "center", flexWrap: "wrap", gap: "1rem" }}>
                                <div style={{ height: "200px", width: "100%", backgroundColor: "#AC9090", borderRadius: "5px" }}>

                                </div>

                                <div style={{ height: "200px", width: "100%", backgroundColor: "#AC9090", borderRadius: "5px" }}>

                                </div>


                            </div>
                        </div>
                    </DivIneer2> */}
        </DIV>

        {/* <div>
                    <FeaturedProductsCrousel/>
                </div> */}

        <div
          dangerouslySetInnerHTML={{ __html: reqBlog.data2 }}
          style={{ padding: "1rem 10vw", textAlign: "center" }}
        ></div>
      </Container>
    </>
  );
};

export default Blog;
