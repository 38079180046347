import {
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../redux/cartRedux";



const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Price = styled.div`
background-color: white;
display: flex;
align-items: center;
justify-content: center;
margin: 10px;
transition: all 0.5s ease;
&:hover {
  background-color: #e9f5f5;
  transform: scale(1.1);
}
`


const Container = styled.div`
  flex: 1;
  margin: 5px;
  min-width: 280px;
  max-width:380px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5fbfd;
  position: relative;
  ${mobile({ minWidth: "150px", minHeight: "230px" })}


  &:hover ${Info}{
    opacity: 1;
  }
  &:hover ${Price}{
    opacity: 1;
  }


`;

const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  
`;

const Image = styled.img`
  height: 75%;
  width:75%;
  z-index: 2;
  object-fit: cover; /* or "cover" */

`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const P = styled.p`
padding: 10px 0;
${mobile({ fontSize: "12px" })}

`

const DIVVV = styled.div`
padding: 10px 40px;
// ${mobile({ padding:"10px 20px",gap:"8px" })}


`




const Product = ({ item }) => {

  const dispatch = useDispatch();

  // const id = location.pathname.split('/')[2];
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");

  console.log(item);





  const handleCartClick = () => {

    dispatch(
      addProduct({ ...item, quantity, color, size })
    )
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }

  return (
    <>
      <Container>
        {/* <Circle /> */}
        <Image src={item.img} />
        <Info >
          <Icon>
            <Link to={`/product/${item._id}`}>
              <SearchOutlined />
            </Link>
          </Icon>
          <Icon>
            <div onClick={handleCartClick}>
              <ShoppingCartOutlined />
            </div>
          </Icon>
        </Info>
        <DIVVV>
          <P>
            {item.title}

          </P>
          <p style={{ fontWeight: "600" }}>{"   ₹"} {item.price}</p>
          <br />

          {/* <button>Hello world</button> */}

        </DIVVV>
      </Container>
    </>

  );
};

export default Product;
