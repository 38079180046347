import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: null,
        isFetching: false,
        error: false
    },
    reducers: {
        loginStart: (state) => {
            state.isFetching = true
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        updateUser: (state, action) =>{
            console.log("Print");
            state.currentUser = action.payload;
        },
        logoutUser: (state) => {
            state.currentUser = null;
        }
    },
});


export const { loginStart, loginSuccess, loginFailure,updateUser,logoutUser } = userSlice.actions;
export default userSlice.reducer;