export const sliderItems = [
  {
    id: 1,
    title: "Ganesh Pooja",
    description:
      "Invoke the blessings of Lord Ganesh, the remover of obstacles, with our Ganesh Pooja service.",
    img: "../images/sliderImages/ganesh.png",
    link: "/products/ganeshPooja",
    bg: "#F7EDEB",
  },
  {
    id: 2,
    title: "Navagraha Pooja",
    description:
      "Attain harmony and balance in your life with our Navagraha Pooja service. ",
    img: "../images/sliderImages/navagrahaPooja.jpg",
    link: "/products/navgrahaPooja",
    bg: "#E6F0FF",
  },
  {
    id: 3,
    title: "Lakshmi Kubera Pooja",
    description:
      "Invite wealth, abundance, and prosperity into your life with our Lakshmi Kubera Pooja service. ",
    img: "../images/sliderImages/shivKuber.png",
    link: "/products/kuberPooja",
    bg: "#F3F5F7",
  },
  {
    id: 4,
    title: "Satyanarayan Katha",
    description:
      "Partake in the divine Satyanarayan Katha, a ritual performed to express gratitude",
    img: "../images/sliderImages/satya.png",
    link: "/products/kathaPooja",
    bg: "#F9F8F0",
  },
];

export const categories = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715161729078WhatsApp%20Image%202024-05-08%20at%201.52.30%20PM.jpeg?alt=media&token=fe250e12-1989-4c4e-ab9a-2b4b1b186f8c",
    title: "Loose Beads",
    cat: "loose_beads",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715161884158WhatsApp%20Image%202024-05-08%20at%201.52.30%20PM.jpeg?alt=media&token=51ee263d-c188-40b5-a667-838d1ea93966",
    title: "Kavach and Kanthas",
    cat: "kavach_kanthas",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715161923802WhatsApp%20Image%202024-05-08%20at%201.52.31%20PM.jpeg?alt=media&token=9dd6478b-f261-40b2-a626-8e0818e58e64",
    title: "Malas, braslets & Rosery",
    cat: "malas",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715161964682WhatsApp%20Image%202024-05-08%20at%201.52.31%20PM%20(1).jpeg?alt=media&token=e1122bc2-18a8-4c15-8057-a63620d9a72f",
    title: "Shivling, Shaligram & Stone idols",
    cat: "shivling_shaligram",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715162000887WhatsApp%20Image%202024-05-08%20at%201.52.31%20PM%20(2).jpeg?alt=media&token=2f7e2dea-b99b-4cd0-866c-aaf5fa6ded02",
    title: "Brass Idols and Figures",
    cat: "brass_figures",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715162055651WhatsApp%20Image%202024-05-08%20at%201.52.32%20PM.jpeg?alt=media&token=67c81adf-87a3-414f-87ff-8d666f3ba45a",
    title: "Yantras, Shankhs & Pooja Articles",
    cat: "yantras",
  },
];
