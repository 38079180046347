import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 10%;
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const Bold = styled.div`
  font-weight: bold;
`;

const ShippingDetails = () => {
  return (
    <Container>
      <Navbar />
      <div>
        <Div style={{ minHeight: "70vh" }}>
          <Title style={{ textAlign: "center" }}>
            Shipping & Delivery Policy
          </Title>
          <Div1>
            <p>
              All ordered products will be shipped from Varanasi, Uttar Pradesh,
              India.
            </p>{" "}
          </Div1>

          <Div1>
            We consistently strive to deliver your order promptly with quality
            packaging, within a reasonable time frame depending upon shipment
            destination.
          </Div1>

          <Div1>
            Shipping within India typically takes 0-7 working days post
            dispatch.
          </Div1>

          <Div1>
            Our shipping operates every day, excluding Sundays and Public
            Holidays.
          </Div1>
          <Div1>
            We use reputable courier agencies, ensuring that your order reaches
            you in a good condition and in best possible time frame.{" "}
          </Div1>
          <Div1>
            In case if the courier service is not available in your area, we
            ship your order via government registered speed post.{" "}
          </Div1>

          <Div1>
            Additional requests, such as silver cap or locket, may extend
            dispatch time by up to three working days.
          </Div1>

          <Div1>
            Sometimes, unforeseen delay may occur due to factors beyond our
            control. In such instances kindly email your query and phone contact
            details to vishweshwararudraksha@gmail.com, we will promptly respond
            with the status and keep you informed.
          </Div1>
          <Div1>
            Vishweshwara Rudraksha will not be liable for delays resulting from
            incomplete or inaccurate address listings. In such instances
            customers are responsible for reshipping charges and no cancellation
            will be permitted.
          </Div1>
          <Div1>
            In rare situations , where you receive a consignment with tampered
            packaging upon delivery, kindly refuse to accept it and return it to
            the delivery person . Immediately contact us at +917007303737 or
            email us at vishweshwararudraksha@gmail.com specifying your order
            and delivery details so that we could reach out you to address your
            concern.
          </Div1>
          <Div1>
            You are requested to please note that delivery timelines mentioned
            are indicative only. In exceptionally rare instances, delay may
            occur due to factors beyond our control, such as unexpected travel
            delays from courier partners, transporters affected by weather
            conditions, strikes, and acts of god.
          </Div1>
        </Div>
      </div>

      <Footer />
    </Container>
  );
};

export default ShippingDetails;
