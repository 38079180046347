import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { mobile } from "../responsive";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const PoojaServicescarousel = () => {
  const [products, setProducts] = useState([]);
  const cat = "pooja";

  useEffect(() => {
    const getProducts = async () => {
      try {
        // const res = await axios.get(cat ? `https://ecommerce-api-rudraksha.onrender.com/api/products?category=${cat}` : "https://ecommerce-api-rudraksha.onrender.com/api/products");
        const res = await axios.get(
          cat
            ? `https://api-ecommerce-bay.vercel.app/api/products?category=${cat}`
            : "https://api-ecommerce-bay.vercel.app/api/products"
        );
        setProducts(res.data);
      } catch (err) {}
    };
    getProducts();
  }, [cat]);

  return (
    <div
      div
      style={{
        minHeight: "40vh",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "relative",
        textDecoration: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FF9933",
          padding: "0.3rem",
          color: "white",
        }}
      >
        <h6
          style={{
            fontSize: "28px",
            ...(mobile ? { fontSize: "20px" } : { marginRight: "1rem" }),
          }}
        >
          Pooja Services
        </h6>
      </div>

      <div style={{ margin: "0 1rem" }}>
        <Carousel responsive={responsive}>
          {products
            ? products.map((product, index) => {
                return (
                  <Link key={product._id} to={`/product/${product._id}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "0 1rem",
                        minWidth: "150px",
                        height: "220px",
                        backgroundColor: "#e9f5f5",
                        margin: "0 15px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "95%",
                          height: "85%",
                          objectFit: "cover",
                        }}
                        src={product.img}
                        alt="akje"
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        {product.title} ₹ {product.price}
                      </span>
                    </div>
                  </Link>
                );
              })
            : null}
        </Carousel>
        ;
      </div>
    </div>
  );
};

export default PoojaServicescarousel;
