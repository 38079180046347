import React from 'react';

import youtubeLogo from "../images/youtube.png"


const YoutubePromoComponent = () => {
    const containerStyle = {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const imageStyle = {
        width: '200px',
        height: 'auto',
        marginBottom: '10px',
    };

    const buttonStyle = {
        backgroundColor: '#ff0000', // YouTube logo red color
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        fontSize: '16px',
        cursor: 'pointer',
    };

    const youtubeChannelLink = 'https://youtube.com';

    return (
        <div style={containerStyle}>
            <img
                src={youtubeLogo}
                alt="Product Image"
                style={imageStyle}
            />
            <a
                href={youtubeChannelLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
            >
                <button style={buttonStyle}>Visit Our YouTube Channel</button>
            </a>
        </div>
    );
};

export default YoutubePromoComponent;
