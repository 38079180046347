import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import Announcement from "../components/Announcement";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
// import Newsletter from "../components/Newsletter";
import Faq from "../components/Newsletter";
import Products from "../components/Products";
import Slider from "../components/Slider";
import TherapySection from "../components/rudraByTherapy";
import { updateUser } from "../redux/userRedux";
import TestimonialSection from "../components/TestimonialSection";
import WhyUsSection from "../components/WhyUs";
import Consulation from "../components/consultation";
import PoojaServicescarousel from "../components/poojaServicescarousel";
import BlogsComponent from "../components/BlogsComponent";
import styled from "styled-components";
import { mobile } from "../responsive";
import axios from "axios";
import Product2 from "../components/product2";
import WriteReviewSection from "../components/WriteReviewSection";
import ReviewSection from "../components/AllReviews";
import YoutubePromoComponent from "../components/YoutubeSection";

const Div = styled.div`
  padding: 1.5rem 6rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;
  ${mobile({ padding: "1rem 2rem", fontSize: "0.9rem" })}
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 2rem;
  ${mobile({ margin: "1rem 1rem" })}
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  width: 300px;

  border-radius: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  border: 1px solid gray; /* Adding gray border */
  ${mobile({ width: "200px" })}
`;

const Container2 = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  
`;


const Wrappper = styled.div`
display:flex;
align-items:center;
gap:2rem;
justify-content: space-around;
flex-wrap:wrap;

`

const Home = () => {
  const [products, setProducts] = useState([]);

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get(
          "https://api-ecommerce-bay.vercel.app/api/products/"
        );
        setProducts(res.data);
        // console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getProducts();
  }, []);

  let user = useSelector((state) => state.user.currentUser);
  // console.log(user);

  const [searchText, setSearchText] = useState("");

  const handleInputChange = (event) => {
    if (event.target.value === "") {
      setFilteredProducts([]);
    } else {
      const requiredFilteredArray = products.filter((product) =>
        product.title.toLowerCase().includes(event.target.value)
      );
      // console.log(requiredFilteredArray);
      setFilteredProducts(requiredFilteredArray);
    }
  };

  const redirectToWhatsApp = () => {
    // Replace the following number with the actual WhatsApp number you want to contact
    const phoneNumber = "7007303737";

    // Construct the WhatsApp URL with the number and a default message (optional)
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello%20from%20my%20website!`;

    // Redirect the user to the WhatsApp URL
    window.location.href = whatsappUrl;
  };

  const buttonStyle = {
    position: "static",
    top: "300px",
    left: "0px",
    backgroundColor: "#25D366", // Green color of WhatsApp logo
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transform: "rotate(270deg)", // Rotate the button 270 degrees
    transformOrigin: "right center", // Rotate around the right center point
    zIndex: "100",
  };

  const buttonHoverStyle = {
    backgroundColor: "#128C7E", // Darker shade on hover
  };

  const consultationButtonStyle = {
    backgroundColor: "#007BFF", // Blue color
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transform: "rotate(270deg)", // Rotate the button 270 degrees
    transformOrigin: "right center", // Rotate around the right center point
    zIndex: "100",
  };

  const consultationButtonHoverStyle = {
    backgroundColor: "#0056b3", // Darker shade on hover
  };

  const containerStyle = {
    position: "fixed",
    right: "20px",
    top: "25%",
    transform: "translateY(-50%)",
    // zIndex: '100'
  };
  const containerStyle2 = {
    position: "fixed",
    right: "20px",
    top: "60%",
    transform: "translateY(-50%)",
    // zIndex: '100'
  };

  return (
    <div>
      {/* <Announcement /> */}
      <Navbar />
      <Wrappper>
        <div>
        <SearchBarContainer>
          <div>
            <SearchInput
              type="text"
              placeholder="Search..."
              onChange={handleInputChange}
            />
          </div>
        </SearchBarContainer>
        <Container2>
          {filteredProducts.map((item) => (
            <Product2 item={item} key={item.id} />
          ))}
        </Container2>
        </div>

        <Link to="/products/Rudraksha" style={{ textDecoration: "none" }}>
           <div
            style={{
              width: "100px",
              backgroundColor: "#FF9933",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Rudraksha
          </div>
        </Link>


        <Link to="/products/pooja" style={{ textDecoration: "none" }}>
         <div
            style={{
              width: "80px",
              backgroundColor: "#FF9933",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Pooja Services
          </div>
        </Link>
        <Link to="/products/kavach_kanthas" style={{ textDecoration: "none" }}>
          <div
            style={{
              width: "80px",
              backgroundColor: "#FF9933",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Rudraksha Combinations
          </div>
        </Link>

        <Link to="/blogs" style={{ textDecoration: "none" }}>
           <div
            style={{
              width: "100px",
              backgroundColor: "#FF9933",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Blogs
          </div>
        </Link>

        <Link to="/orders" style={{ textDecoration: "none" }}>
           <div
            style={{
              width: "100px",
              backgroundColor: "#FF9933",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            Orders
          </div>
        </Link>

         

        
        

        
      </Wrappper>
      <Slider />
      <TherapySection />
      <Consulation />
      <div style={containerStyle}>
        <button
          style={buttonStyle}
          onClick={redirectToWhatsApp}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = buttonHoverStyle.backgroundColor;
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = buttonStyle.backgroundColor;
          }}
        >
          Chat on WhatsApp
        </button>
      </div>
      <div style={containerStyle2}>
        <Link to="/consultation">
          <button
            style={consultationButtonStyle}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor =
                consultationButtonStyle.backgroundColor;
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor =
                consultationButtonStyle.backgroundColor;
            }}
          >
            Personal Consultation
          </button>
        </Link>
      </div>
      <PoojaServicescarousel />
      <Div>
        <p> "Experience the Divinity of kashi"</p>
      </Div>
      <Categories />
      <Div>
        <p>Show with confidence, shop at Vishweshvara rudraksha</p>
      </Div>
      <Products />
      <Div>
        <p>
          It is said that wearing Rudraksha beads can help balance the chakras
          and promote physical, mental, and emotional harmony.
        </p>
      </Div>
      <YoutubePromoComponent />
      <BlogsComponent />
      <WriteReviewSection />
      <ReviewSection />
      <WhyUsSection />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
