import { createSlice } from "@reduxjs/toolkit"

const billingDataSlice = createSlice({
    name:"data",
    initialState:{
        address:null,
        mobile:null,
    },
    reducers:{
        addBillingDetails: (state, action) =>{
            state.address = action.payload.address;
            state.mobile =action.payload.mobile;
        },
    },
    // reducers:{

    //     addBillingDetails : (state,action) => {
    //         state.address = action.payload.billingAddress,
    //         state.mobile = action.payload.mobile
    //     },
    // }
});


export const {addBillingDetails } = billingDataSlice.actions;
export default billingDataSlice.reducer;