import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { incQuantity, decQuantity } from "../redux/cartRedux";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { addBillingDetails } from "../redux/billingDataRedux";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  ${mobile({ display: "none" })}
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  min-height: 50vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
`;

const Div = styled.div`
margin-top:20px;
padding:0 10vw;
display:flex;
align-items:center;
justify-content:center;
gap:5vw;
${mobile({ flexDirection: "column" })}

`
const Divv = styled.div`
padding:0 20px;
display:flex;
align-items:center;
justify-content:center;
width:150px;
height:150px;
border-radius:10px;
background-color:#FF9933;
color:white;
border:black;
${mobile({ width: "100px",height:"100px" })}

`

const Cart = () => {
  const [user, setUserData] = useState("");
  const [coupon,setCoupon] = useState("");
  const [discount,setDiscount] = useState(0);

  const { currentUser, isFetching, error } = useSelector((state) => state.user);

  useEffect(() => {
    setUserData(currentUser);
  }, []);

  const [userId, setUserId] = useState("");
  const [cartObj, setCartObj] = useState({});

  const [billingDetails, setBillingDetails] = useState({
    mobile: "",
    address: "",
    email: "",
  });

  const cart = useSelector((state) => state.cart);



  useEffect(() => {
    if(currentUser){
      setUserId(currentUser._id);
      setCartObj(cart);  
    }
  }, []);

  const data = useSelector((state) => state.data);
  console.log(data);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setBillingDetails({ ...billingDetails, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();


  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } 
  }, []);




  const checkout = async (amount) => {

    if(amount == 0){
      window.alert("Please add some items in cart to continue");
    }


    try {
      console.log("yes");
      dispatch(addBillingDetails({ ...billingDetails }));

      console.log(cart);
      const res = await axios.post(
        "https://api-ecommerce-bay.vercel.app/api/checkout/create-order",
        {
          amount: Number(amount * 100),
          email: user.email,
        }
      );
      if (res.status != 200) {
        alert("Error doing checkout");
        return;
      }

      console.log(res.data.order);
      const order = res.data.order;
      var options = {
        key: "rzp_test_Wt0TVMyKOjWTbA", // Enter the Key ID generated from the Dashboard
        amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Acme Corp", //your business name
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url:
          "https://api-ecommerce-bay.vercel.app/api/checkout/paymentverification/?userid=" +
          encodeURIComponent(userId) +
          "&cart=" +
          encodeURIComponent(JSON.stringify(cart)) +
          "&bookingDetails=" +
          encodeURIComponent(JSON.stringify(billingDetails)),
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: "Gaurav Kumar", //your customer's name
          email: user.email,
          contact: billingDetails.mobile, //Provide the customer's phone number for better conversion rates
        },
        notes: {
          id:userId,
          billingDetails: billingDetails,
          cart:cartObj,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const razorpay = new window.Razorpay(options);

      razorpay.open();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Navbar />
      {/* <Announcement /> */}
      <Wrapper>
        <Title>YOUR BAG</Title>

        <Top>
          <Link to="/">
            <TopButton>CONTINUE SHOPPING</TopButton>
          </Link>
          <TopTexts>
            <TopText>Shopping Bag</TopText>
            {/* <TopText>Your Wishlist (0)</TopText> */}
          </TopTexts>
          {/* <TopButton type="filled">CHECKOUT NOW</TopButton> */}
        </Top>
        <Bottom>
          <Info>
            {cart.products.map((product, index) => (
              <Product key={index}>
                <ProductDetail>
                  <Image src={product.img} />
                  <Details>
                    <ProductName>
                      <b>Product:</b> {product.title}
                    </ProductName>
                    <ProductId>
                      <b>ID:</b> {product._id}
                    </ProductId>
                    {/* <ProductColor color="black" />
                    <ProductSize>
                      <b>Size:</b> {product.size}
                    </ProductSize> */}
                  </Details>
                </ProductDetail>
                <PriceDetail>
                  <ProductAmountContainer>
                    <span style={{ cursor: "pointer" }}>
                      <Add
                        onClick={() =>  
                          dispatch(
                            incQuantity({ index: index, price: product.price })
                          )
                        }
                      />
                    </span>
                    <ProductAmount>{product.quantity}</ProductAmount>
                    <span style={{ cursor: "pointer" }}>
                      <Remove
                        onClick={() =>
                          dispatch(
                            decQuantity({ index: index, price: product.price })
                          )
                        }
                      />
                    </span>
                  </ProductAmountContainer>
                  <ProductPrice>
                    {product.price * product.quantity} rs
                  </ProductPrice>
                </PriceDetail>
              </Product>
            ))}

            <Hr />
          </Info>
          <Summary>
            <SummaryTitle>ORDER SUMMARY</SummaryTitle>
            <SummaryItem>
              <SummaryItemText>Subtotal</SummaryItemText>
              <SummaryItemPrice>{cart.total} rs</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Estimated Discount</SummaryItemText>
              <SummaryItemPrice>{discount}</SummaryItemPrice>
            </SummaryItem>
           
            <SummaryItem type="total">
              <SummaryItemText>Total</SummaryItemText>
              <SummaryItemPrice>{cart.total} rs</SummaryItemPrice>
            </SummaryItem>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                margin: "1rem 0",
              }}
            >
              <textarea
                style={{ padding: "0.3rem" }}
                rows={5}
                type="text"
                placeholder="address"
                name="address"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <input
                style={{ padding: "0.3rem" }}
                type="number"
                placeholder="mobile"
                name="mobile"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              
              <input
                style={{ padding: "0.3rem" }}
                type="email"
                placeholder="email"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
              />

              <div style={{display:"flex",justifyContent:"space-between"}}>
              <input
                style={{ padding: "0.3rem" }}
                type="coupon"
                value={coupon}
                
                placeholder="coupon"
                name="coupon"
                onChange={(e)=>setCoupon(e.target.value)}
              />
              <span 
              // onClick={handleAddDiscount}
               style={{padding:"3px 5px",borderRadius:'8px',cursor:"pointer",backgroundColor:"#FF9933",color:"white"}}>Apply</span>
                
              </div>
              
            </div>

            <Button
              onClick={() => {
                !billingDetails.mobile || !billingDetails.address || !billingDetails.email
                  ? alert("Give valid address and mobile number.")
                  : checkout(cart.total);
              }}
            >
              Checkout Now
            </Button>
          </Summary>
        </Bottom>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Cart;
