import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 10%;
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const Bold = styled.div`
  font-weight: bold;
`;

const Contact = () => {
  return (
    <Container>
      <Navbar />
      <div>
        <Div style={{ minHeight: "70vh" }}>
          <Title style={{ textAlign: "center" }}>Contact Us</Title>
          <Div1>
            <p>
              Feel free to contact us!
              <br />
              We love assisting our customers
              <br />
              Let us know if you have a specific question or give us an idea of
              what you 're looking for and we are happy to offer you
              personalized recommendation.
              <br />
              <br />
              Operational Address :
              <br />
              <Bold> CK-36/6, GYANVAPI, VARANASI, UTTAR PRADESH, 221001</Bold>
              <br />
              Legal Entity name:<Bold>Shiv Rudraksha Ratna Emporium.</Bold>
              <br />
              {/* Registered Office Address:
              <Bold>CK 36/6 Gyanvapi, Varanasi, 221001</Bold>
              <br /> */}
              Email id :<Bold>vishweshwararudraksha@gmail.com</Bold>
              <br /> Phone No:
              <Bold>+91 7007303737</Bold>
            </p>{" "}
          </Div1>
        </Div>
      </div>

      <Footer />
    </Container>
  );
};

export default Contact;
