import styled from "styled-components";
import { mobile } from "../responsive";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    // url("https://images.pexels.com/photos/6984661/pexels-photo-6984661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
    center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: #ff9933;
  color: white;
  cursor: pointer;
`;

const Register = () => {
  const [credentials, setCredentials] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  console.log(credentials);

  const navigate = useNavigate();

  const handleCreate = (e) => {
    e.preventDefault();
    console.log(credentials);
    if (credentials.password !== credentials.confirmPassword) {
      window.alert("Confirm Password is not matching the password");
    } else if (credentials.password.length < 6) {
      window.alert("Password must be at least 6 characters");
    } else if (credentials.username.length < 6) {
      window.alert("Username must be at least 6 characters");
    } else {
      // axios.post("https://ecommerce-api-rudraksha.onrender.com/api/auth/register", {
      //   username: credentials.username,
      //   email: credentials.email,
      //   password: credentials.password,
      // }).then((res)=>{
      //   console.log(res.data);
      // })
      axios
        .post("https://api-ecommerce-bay.vercel.app/api/auth/register", {
          username: credentials.username,
          email: credentials.email,
          password: credentials.password,
        })
        .then((res) => {
          alert(res?.data.message);
          navigate("/login");
        })
        .catch((err) => {
          alert(err?.response?.data?.message);
          console.log(err?.response?.data?.message);
        });
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>CREATE AN ACCOUNT</Title>
        <Form>
          <Input name="name" placeholder="Name" onChange={handleChange} />
          <Input
            name="username"
            placeholder="username"
            onChange={handleChange}
          />
          <Input
            name="email"
            placeholder="email"
            type="email"
            onChange={handleChange}
          />
          <Input
            name="password"
            placeholder="password"
            type="password"
            onChange={handleChange}
          />
          <Input
            name="confirmPassword"
            type="password"
            placeholder="confirm password"
            onChange={handleChange}
          />
          <Agreement>
            By creating an account, I consent to the processing of my personal
            data in accordance with the <b>PRIVACY POLICY</b>
          </Agreement>
          <Button onClick={handleCreate}>CREATE</Button>
        </Form>
        <div style={{ marginTop: "1rem" }}></div>
        <Link to="/login">
          Already have an Account? <span style={{ color: "blue" }}>Login</span>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Register;
