import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest } from "../requestMethods";

export const login = async (dispatch, user) => {
  console.log(user);
  dispatch(loginStart());

  try {
    const res = await publicRequest.post("/api/auth/login", user);
    localStorage.setItem("persist:root", res.data.accessToken);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    window.alert(err);
    dispatch(loginFailure());
  }
};
