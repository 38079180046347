import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useState } from "react";
import axios from "axios";

const Consulation = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSuccess, setMessageSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api-ecommerce-bay.vercel.app/api/consultation",
        {
          name,
          number,
          message,
        }
      );
      setMessageSuccess(true);
      console.log(response.data);
      // You can handle the response as needed (e.g., show a success message)
    } catch (error) {
      console.error(error);
      // Handle error scenarios (e.g., show an error message)
    }
  };

  return (
    <>
      <Navbar />
      {isMessageSuccess ? (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <h2 style={{ padding: "0 2rem" }}>
            Your message has been received, We will contact you back shortly,
            refresh the screen to send another message
          </h2>
        </div>
      ) : (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <h1 style={{ marginBottom: "30px", fontSize: "2rem" }}>
            Personal Consultation Form
          </h1>
          <form
            style={{
              width: "100%",
              maxWidth: "400px",
              padding: "20px",
              paddingRight: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
            onSubmit={handleSubmit}
          >
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="name"
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{
                  width: "90%",
                  padding: "10px",
                  fontSize: "1rem",
                  borderRadius: "3px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="number"
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              >
                Number:
              </label>
              <input
                type="text"
                id="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
                style={{
                  width: "90%",
                  padding: "10px",
                  fontSize: "1rem",
                  borderRadius: "3px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="message"
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              >
                Message:
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                style={{
                  width: "90%",
                  padding: "10px",
                  fontSize: "1rem",
                  borderRadius: "3px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <button
              type="submit"
              style={{
                background: "#007bff",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "1.2rem",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Consulation;
