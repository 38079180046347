import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";
import Img1 from "../images/sliderImages/ganesh.png";
import Img2 from "../images/sliderImages/navagrahaPooja.jpg";
import Img3 from "../images/sliderImages/shivKuber.png";
import Img4 from "../images/sliderImages/satya.png";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ height: "auto", padding: "2.5rem 0" })}
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
  ${mobile({ height: "25vh" })}
`;

const ImgContainer = styled.div`
  height: 100%;
  flex: 1;
`;

const Image = styled.img`
  height: 80%;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 50px;
  ${mobile({ padding: "20px" })}
`;

const Title = styled.h1`
  font-size: 70px;
  ${mobile({ fontSize: "20px" })}
`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobile({ margin: "20px 0", fontSize: "12px" })}
`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

const DIVVV1 = styled.div`
  width: 100vw;
  height: 90vh;
  background: url("https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715164282731Screenshot%20from%202024-05-08%2015-34-32.png?alt=media&token=67c1329a-d314-4583-8ce4-fdb0f04f6ca");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  margin: auto;
  padding: 0;
  position: relative;
  cursor: pointer;
  ${mobile({ height: "100%", padding: "0 1rem" })}
`;

const DIVVV2 = styled.div`
  width: 100vw;
  cursor: pointer;

  height: 90vh;
  background: url("https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715631715945Screenshot%20from%202024-05-14%2001-51-25.png?alt=media&token=7fef94bb-1da8-452d-b636-7f3fb147d8b7");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  margin: auto;
  padding: 0;
  position: relative;
  ${mobile({ height: "100%", padding: "0 2rem" })}
`;
const DIVVV3 = styled.div`
  width: 100vw;
  height: 90vh;
  background: url("https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715631569461WhatsApp%20Image%202024-05-11%20at%209.06.44%20PM.jpeg?alt=media&token=7afd051d-db92-49f0-b7aa-dd4e7c3f1e51");
  background-size: contain;
  cursor: pointer;

  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  margin: auto;
  padding: 0;
  position: relative;
  ${mobile({ height: "100%", padding: "0 1rem" })}
`;
const DIVVV4 = styled.div`
  width: 100vw;
  height: 90vh;
  background: url("https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715166112556Screenshot%20from%202024-05-08%2016-31-39.png?alt=media&token=75651d60-e5c5-4d49-a2e5-baed3952b830");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  cursor: pointer;

  margin: auto;
  padding: 0;
  position: relative;
  ${mobile({ height: "100%", padding: "0 1rem" })}
`;

const Slider = () => {
  // console.log(sliderItems);
  const [slideIndex, setSlideIndex] = useState(0);
  const navigate = useNavigate();
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 3);
    } else {
      setSlideIndex(slideIndex < 3 ? slideIndex + 1 : 0);
    }
  };

  const handleSliderClick = (link) => {
    navigate(link);
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {/* {sliderItems.map((item) => ( */}
        <Slide>
          <DIVVV1
            onClick={() => handleSliderClick("/products/loose_beads")}
          ></DIVVV1>

          {/* <img
              style={{ backgroundRepeat: "no-repeat", objectFit: "cover" }}
              src="https://firebasestorage.googleapis.com/v0/b/rudraksh-ecommerce.appspot.com/o/1715164282731Screenshot%20from%202024-05-08%2015-34-32.png?alt=media&token=67c1329a-d314-4583-8ce4-fdb0f04f6cad"
            ></img> */}
          {/* <ImgContainer>

              {
                item.id === 1 ? (<Image
                  src={Img1}
                />) : (
                  item.id === 2 ? (<Image
                    src={Img2}
                  />) : (
                    item.id === 3 ? (<Image
                      src={Img3}
                    />) : (
                      item.id === 4 ? (<Image
                        src={Img4}
                      />) : (
                        <></>
                      )
                    )
                  )
                )
              }

            </ImgContainer>
            <InfoContainer>
              <Title>{item.title}</Title>
              <Desc>{item.description}</Desc>
              <Link to={item.link}>
                <Button>BOOK NOW</Button>
              </Link>
            </InfoContainer> */}
        </Slide>

        <Slide>
          <DIVVV2 onClick={() => handleSliderClick("/products/pooja")}></DIVVV2>
        </Slide>
        <Slide>
          <DIVVV3
            onClick={() => handleSliderClick("/products/kavach_kanthas")}
          ></DIVVV3>
        </Slide>
        <Slide>
          <DIVVV4
            onClick={() => handleSliderClick("/products/shivling_shaligram")}
          ></DIVVV4>
        </Slide>
        {/* ))} */}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

export default Slider;
