import React, { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import axios from "axios";

const ReviewSectionWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  border-top: 1px solid #ccc;
`;

const ReviewForm = styled.form`
  display: ${(props) => (props.visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
  width: 70vw;
  gap: 1rem;
  margin: auto;
  ${mobile({ width: "80vw" })}
  margin-top: 1.5rem;
`;

const ReviewButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  border-radius: 10px;
  color: white;
  border: none;
  cursor: pointer;
`;

const ReviewList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ReviewItem = styled.li`
  margin-bottom: 10px;
`;

const Span = styled.span``;
const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

const DivNaE = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;

  ${mobile({ flexDirection: "column" })}
`;

const Inputt = styled.input`
  width: 40%;
  padding: 8px 16px;
`;

const WriteReviewSection = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [review, setReview] = useState({
    title: "",
    review: "",
    name: "",
    email: "",
    rating: "5",
  });

  const setReviewText = (e) => {
    const { name, value } = e.target;

    setReview((prev) => ({
      ...prev,
      [name]: value,
    }));

    console.log(review);
  };
  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleReviewSubmit = (event) => {
    event.preventDefault();

    console.log(review);
    try {
      const res = axios.post(
        "https://api-ecommerce-bay.vercel.app/api/review/create",
        {
          review,
        }
      );

      console.log(res);

      setReview({
        title: "",
        review: "",
        name: "",
        email: "",
        rating: "5",
      });
      window.alert(
        "Review Submitted Successfully, We are trying to verifying the review and will be updated in the review section after verifiying"
      );
    } catch (err) {
      window.alert("Some Error occurred", err);
    }
  };

  return (
    <ReviewSectionWrapper>
      <Title>Reviews</Title>
      <Div>
        {/* <Span>Help you Website Grow by writing a positive Review</Span> */}
        <ReviewButton onClick={toggleFormVisibility}>
          Write a Review
        </ReviewButton>
      </Div>
      <ReviewForm visible={isFormVisible} onSubmit={handleReviewSubmit}>
        <input
          onChange={setReviewText}
          value={review.title}
          name="title"
          style={{ width: "100%", padding: "8px 16px" }}
          required
          placeholder="* Title"
        ></input>
        <textarea
          required
          value={review.review}
          name="review"
          style={{ width: "100%", padding: "8px 16px" }}
          onChange={setReviewText}
          placeholder="*Write your review here..."
          rows="4"
        />
        <DivNaE>
          <Inputt
            onChange={setReviewText}
            name="name"
            style={{ flex: "1" }}
            value={review.name}
            required
            placeholder="* Your Name"
          ></Inputt>
          <Inputt
            onChange={setReviewText}
            name="email"
            type="email"
            value={review.email}
            style={{ flex: "1" }}
            required
            placeholder="* Your Email"
          ></Inputt>
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <label>Rating</label>
            <select
              style={{ padding: "5px 10px" }}
              value={review.rating}
              onChange={setReviewText}
              name="rating"
            >
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </select>
          </div>
        </DivNaE>

        <br />
        <button
          style={{
            padding: "5px 10px",
            color: "white",
            backgroundColor: "#007bff",
            border: "0px ",
            borderRadius: "10px",
            fontSize: "18px",
            cursor: "pointer",
          }}
          type="submit"
        >
          Submit Review
        </button>
      </ReviewForm>
      {/* <ReviewList>
        {reviews.map((review, index) => (
          <ReviewItem key={index}>{review}</ReviewItem>
        ))}
      </ReviewList> */}
    </ReviewSectionWrapper>
  );
};

export default WriteReviewSection;
