import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { userRequest } from "../requestMethods";

const Success = () => {
  // const location = useLocation();
  // const [isNewOrderCreated, setIsNewOrderCreated] = useState(false);

  const TOKEN = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root")).user
  ).currentUser.accessToken;
  const [order, setOrders] = useState({});
  const currentUser = useSelector((state) => state.user.currentUser);
  // const [orderId, setOrderId] = useState(null);
  // const cart = useSelector((state) => state.cart)
  // const data = useSelector((state) => state.data)

  // // console.log(cart);
  // // console.log(data);

  // console.log(currentUser);

  // useEffect(() => {
  //   const createOrder = async () => {
  //     try {
  //       const res = await axios.post(
  //         // "https://ecommerce-api-rudraksha.onrender.com/api/orders",
  //         "https://api-ecommerce-bay.vercel.app/api/orders",
  //         {
  //           userId: currentUser._id,
  //           // userEmailId: currentUser.email,
  //           products: cart.products.map((item) => ({
  //             productId: item._id,
  //             // productName: item.name,
  //             quantity: item._quantity,
  //           })),
  //           amount: cart.total,
  //           address: data.address,
  //           userMobile: data.mobile,
  //           dateOfBooking: data.poojaDate
  //         },
  //         {
  //           headers: {
  //             token: `Bearer ${currentUser.accessToken}`,
  //           },
  //         }
  //       );
  //       setOrderId(res.data._id);
  //       setIsNewOrderCreated(true);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (data && currentUser && cart) {
  //     createOrder();
  //   }
  // }, [cart, data, currentUser]);

  // useEffect(() => {
  //   if (currentUser) {
  //     try {
  //       axios
  //         .get(
  //           // `https://ecommerce-api-rudraksha.onrender.com/api/orders/find/${currentUser._id}`
  //           `https://api-ecommerce-bay.vercel.app/api/orders/find/${currentUser._id}`,
  //           {
  //             headers: {
  //               token: `Bearer ${TOKEN}`,
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           console.log(res.data);
  //           setOrders(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }, []);

  return <></>;
};

export default Success;
