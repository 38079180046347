import axios from "axios";

//  const BASE_URL ="https://ecommerce-api-rudraksha.onrender.com/";
const BASE_URL = "https://api-ecommerce-bay.vercel.app/";
const TOKEN = "";

// const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
// if (persistRoot) {
//     TOKEN = JSON.parse(persistRoot.User).currentUser.accessToken || '';
// }

// const TOKEN = () => {
//     if (
//       JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user)
//         .currentUser.accessToken
//     ) {
//       return JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user)
//         .currentUser.accessToken;
//     } else { return '' }
//   };

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});
