import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 10%;
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const Bold = styled.div`
  font-weight: bold;
`;

const Refund = () => {
  return (
    <Container>
      <Navbar />
      <div>
        <Div style={{ minHeight: "70vh" }}>
          <Title style={{ textAlign: "center" }}>
            Cancellation & Refund Policy
          </Title>
          <Div1>
            <p>
              Vishweshwara Rudraksha believes in helping its customers as far as
              possible, and has therefore a liberal cancellation policy. Under
              this policy:
            </p>{" "}
            <div>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <li>
                  Cancellation of your order will be permissible before it has
                  been shipped.{" "}
                </li>
                <li>
                  Replacements will be considered solely in the event of
                  dispatching an incorrect item, as our merchandise undergoes a
                  thorough quality check before being shipped.{" "}
                </li>
                <li>
                  Cancellation and Replacement will be entertained on condition
                  that it meets the requirements of cancellation and refund
                  policy stated below:
                  <br />
                  <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                    1. Items must be there in its original unused conditions and
                    sent along with its original bill and tags intact.
                  </span>
                  <br />
                  <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                    2. We will not assume responsibility for reimbursements or
                    compensation for damages due to neglect, improper usage or
                    in event that return packages are lost, stolen or
                    mishandled.{" "}
                  </span>
                  <br />
                  <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                    3. Replacement of items will be entertained after items are
                    inspected and checked through Vishweshwara Rudraksha.
                  </span>
                  <br />
                  <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                    4. We’ll notify you once we have received and inspected your
                    return and let you know if the replacement or refund is
                    approved or not .{" "}
                  </span>
                  <br />
                  <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                    5. If approved then replacement will be replaced and in case
                    of refund the amount will be automatically refunded in your
                    account. Keep in mind that it may take some time for your
                    bank or credit card company to process post refund.
                  </span>
                  <br />
                </li>

                <li>
                  We reserve the right to refuse return/exchange of any
                  merchandise that does not meet the above written requirements
                  in Vishweshwara Rudraksha sole discretion.
                </li>

                <li>
                  In highly improbable event of merchandise arriving damaged,
                  kindly email us a photo of the damaged product along with its
                  packaging. Upon receiving your request, we will confirm
                  through email and phone.
                </li>

                <li>
                  Please courier the damaged item to our Varanasi office within
                  2 working days, and a replacement wiil be provided according
                  to our policy.
                </li>
                <li>
                  Kindly email your exchange request to
                  vishweshwararudraksha@gmail.com within 24 hours of receiving
                  your shipment.
                </li>

                <li>
                  we aim to process all returns and exchanges within 7 working
                  days upon receiving the product.
                </li>

                <li>
                  {" "}
                  Mailing address for exchange :-
                  <div style={{ fontWeight: "600" }}>
                    Shiv Rudraksha Ratna Emporium <br />
                    CK 36/6 Gyanvapi, Varanasi,
                    <br /> 221001, Uttar Pradesh.
                  </div>
                </li>
                <li>
                  An exception to the policy is at sole discretion of
                  Vishweshwara Rudraksha and applicable when the same is
                  communicated to the customer in written.
                </li>
              </ul>
            </div>
          </Div1>
        </Div>
      </div>

      <Footer />
    </Container>
  );
};

export default Refund;
