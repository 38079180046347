import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { mobile } from "../responsive";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const TherapySection = () => {
  const [products, setProducts] = useState([]);
  const cat = "shankhs";

  useEffect(() => {
    const getProducts = async () => {
      try {
        // const res = await axios.get(cat ? `https://ecommerce-api-rudraksha.onrender.com/api/products?category=${cat}` : "https://ecommerce-api-rudraksha.onrender.com/api/products");
        const res = await axios.get(
          cat
            ? `https://api-ecommerce-bay.vercel.app/api/products?category=${cat}`
            : "https://api-ecommerce-bay.vercel.app/api/products"
        );
        setProducts(res.data);
      } catch (err) {}
    };
    getProducts();
  }, [cat]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FF9933",
          color: "white",
          padding: "0.3rem 1rem",
        }}
      >
        <h6
          style={{
            fontSize: "28px",
            textAlign: "center",
            ...(mobile && { flex: 2, marginRight: "1rem", fontSize: "20px" }),
          }}
        >
          Rudraksha in Combination
        </h6>
      </div>

      <div style={{ margin: "0 1rem" }}>
        <Carousel responsive={responsive}>
          {products
            ? products.map((product, index) => {
                return (
                  <Link key={product._id} to={`/product/${product._id}`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "0 1rem",
                        minWidth: "150px",
                        height: "180px",
                        backgroundColor: "#e9f5f5",
                        margin: "0 15px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "95%",
                          height: "85%",
                          objectFit: "cover",
                        }}
                        src={product.img}
                        alt="akje"
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "black",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        {product.title} ₹ {product.price}
                      </span>
                    </div>
                  </Link>
                );
              })
            : null}
        </Carousel>
      </div>
    </div>
  );
};

export default TherapySection;

// < div > Item 1</ >
//             <div>Item 2</div>
//             <div>Item 3</div>
//             <div>Item 4</div>
//             <div>Item ds</div>
//             <div>Item sdk</div>
//             <div>Item 3</div>
//             <div>Item 4</div>
