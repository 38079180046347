import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0 10%;
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const Bold = styled.div`
  font-weight: bold;
`;

const TermsConditions = () => {
  return (
    <Container>
      <Navbar />
      <div>
        <Div>
          <Title style={{ textAlign: "center" }}>Terms and Conditions </Title>
          <Div1>
            <div style={{ fontWeight: "600", fontSize: "1rem" }}>
              1. General
            </div>
            <p>
              The general terms and conditions of sale details below govern the
              contractual relationship between the ‘users’ (here after also
              referred to as ‘you ` or the ‘costumer’) and
              ‘vishweshwararudraksha .com` (here after also referred to as ‘we`
              or the `website’) belonging to the firm Shiv Rudraksha Ratna
              Emporium.
            </p>
            <p>
              <spanf style={{ fontWeight: "600" }}>
                Both parties accept these conditions unreservedly. These general
                conditions of the sale are the only conditions that are
                applicable and replaceable all other conditions except in the
                case of express, written, prior dispensation. We maintain that,
                by confirming your orders, you have read and do unreservedly
                accept
              </spanf>
              our general conditions of sale. This agreement shall be
              interoperated under the laws of Uttar Pradesh, India, without
              giving effect to conflicts – of – law rules; and in the event of a
              dispute under this agreement; buyer submits to exclusive
              jurisdiction and venue of the jurisdiction of Varanasi and hereby
              waives any objection to such jurisdiction and venue. These terms
              and conditions of the sale are important to you and Vishweshwara
              Rudraksha as they are used to protect your rights as a valued
              customer and our rights as a business. This agreement may not be
              modified, altered or amended without the written agreement of
              seller.
            </p>
            <Bold>
              2. Changes to the terms of service and the website:- <br />
            </Bold>
            Vishweshwara Rudraksha is work in progress, and as such things may
            change, be added or be taken away. We reserve the right to update
            the website and these terms of services from time to time at our
            discretion and without any notice. It is solely your responsibility
            to keep up-to-date with the latest terms and conditions by checking
            back regularly. your continued use of updated terms off service will
            be taken to mean that you have read and agreed to the changes.
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div>
                <Bold>
                  3. Product information: - <br />
                </Bold>
                Vishweshwara Rudraksha takes great care when putting product
                information description and images on-line but will not be held
                responsible for any mistakes or omissions to any information
                given.
              </div>
              <div>
                <Bold>
                  4. Fulfillment, pricing and payment terms: -<br />
                </Bold>
                we are committed to fulfillment your completed and paid orders.
                If a product becomes unavailable after your order is confirmed
                and paid within 30 days if we cannot fulfill the order. For
                orders with temporarily unavailable products, we will dispatch
                the available items first and send the outstanding item once
                they became available. The buyer agrees to fully pay the posted
                price of the goods on the website. Prices listed on the website
                do not include shipping costs. Vishweshwara Rudraksha (“seller”)
                hereby argue to purchase, goods of the description and quantity
                described on the final checkout window (“checkout”) and
                incorporated herein by this reference (“goods”) on the terms and
                conditions set forth in this agreement. The net total amount of
                the purchase price shall be payable in full by buyer at
                checkout. Good shall be sent only once the seller receives a
                confirmation of payment.
              </div>
              <div>
                <Bold>
                  5. Delivery: -<br />
                </Bold>
                your order will be shipped to the specified address provided
                during the order placement. Vishweshwara Rudraksha’s shipping
                policy unless explicitly agreed. Upon in writing. Delivery dates
                provided by the seller are estimates only, and while every
                reasonable effort will be made to meet these dates, the seller
                is not liable for any failure to deliver as estimated. Unless
                specified otherwise in writing, goods will be packaged according
                to seller’s standards and practices. your order will be shipped
                to the specified address provided during the order placement.
                Vishweshwara Rudraksha’s shipping policy unless explicitly
                agreed. Upon in writing. Delivery dates provided by the seller
                are estimates only, and while every reasonable effort will be
                made to meet these dates, the seller is not liable for any
                failure to deliver as estimated. Unless specified otherwise in
                writing, goods will be packaged according to seller’s standards
                and practices.
              </div>
              <div>
                <Bold>
                  6. Disclaimer of Warranty /Limitations of Liability:- <br />
                </Bold>
                Seller undertakes no responsibility for the quality of goods for
                any particular purpose for which buyer may be buying the goods
                except as otherwise provided in this agreement, and seller
                disclaims all other warranties and conditions expressed, express
                or implied.
              </div>
              <div>
                <Bold>
                  7. Color Disclaimer: - <br />
                </Bold>
                The color of the product you receive and the ones that you see
                on computer screen will vary slightly from those of the actual
                products. This is because of the difference in the technologies
                used in computer monitors that are not properly calibrated also
                add the difference in colors we see. Additionally the lighting
                used while taking photographs also factor in the final color
                shown on the screen.
              </div>
              <div>
                <Bold>
                  8. Waiver and server-ability:-
                  <br />
                </Bold>
                seller shall not be held responsible for any failure of
                performance to make timely delivery of all or any part of the
                goods in the event such failure was due, in the whole or in the
                part, provincial or municipal action, statute, ordinance or
                regulations, strike or other labor trouble, fire or other damage
                to or destruction of, in whole or in part, the goods or the
                manufacturing facility for the goods, the lack or inability to
                obtain raw materials, labor, fuel, electrical power, water or
                supplies, or any other cause, act of god, contingency or
                circumstances not subject to reasonable control of seller, which
                causes delivery of goods. Seller shall determine in good faith
                the extent to which it can reasonably control a cause,
                contingency, or circumstances that affects the performance of
                its obligation.
              </div>
              {/* <ul>
                <li>
                  The content of the pages of this website is subject to change
                  without notice.
                </li>
                <li>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  found or offered on this website for any particular purpose.
                  You acknowledge that such information and materials may
                  contain inaccuracies or errors and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </li>
                <li>
                  Your use of any information or materials on our website and/or
                  product pages is entirely at your own risk, for which we shall
                  not be liable. It shall be your own responsibility to ensure
                  that any products, services or information available through
                  our website and/or product pages meet your specific
                  requirements.
                </li>
                <li>
                  Our website contains material which is owned by or licensed to
                  us. This material includes, but are not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </li>
                <li>
                  All trademarks reproduced in our website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website.
                </li>
                <li>
                  Unauthorized use of information provided by us shall give rise
                  to a claim for damages and/or be a criminal offense.
                </li>

                <li>
                  From time to time our website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information.
                </li>

                <li>
                  You may not create a link to our website from another website
                  or document without Vishweshwara Rudraksha’s prior written
                  consent.
                </li>

                <li>
                  Any dispute arising out of use of our website and/or purchase
                  with us and/or any engagement with us is subject to the laws
                  of India .
                </li>

                <li>
                  We, shall be under no liability whatsoever in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any Transaction, on Account of
                  the Cardholder having exceeded the preset limit mutually
                  agreed by us with our acquiring bank from time to time
                </li>
              </ul> */}
            </div>
          </Div1>
        </Div>
      </div>

      <Footer />
    </Container>
  );
};

export default TermsConditions;
