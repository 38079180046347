import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import axios from "axios";
import { useEffect } from "react";

const BlogsComponent = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get(
          "https://api-ecommerce-bay.vercel.app/api/blog"
        );
        setBlogs(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBlogs();
  }, []);

  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleBlogClick = (id) => {
    const blog = blogs.find((blog) => blog.id === id);
    setSelectedBlog(blog);
  };

  const handleCloseBlog = () => {
    setSelectedBlog(null);
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    marginBottom: "10px",
    borderRadius: "5px",
  };

  const contentStyle = {
    textAlign: "justify",
  };

  const blogStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: "999",
    overflowY: "scroll",
  };

  const blogContentStyle = {
    width: "600px",
    height: "600px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    overflowY: "scroll",
  };

  const closeStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    backgroundColor: "red",
    color: "white",
    padding: "0.5rem 1rem",
    borderRadius: "1rem",
    fontWeight: "700",
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="blogging-section">
        {/* <Link to="/">
                <a style={{ marginRight: "2rem", fontSize: "2rem", cursor: "pointer" }}>Home</a>
            </Link> */}
        <h1
          style={{
            textAlign: "center",
            marginBottom: "2rem",
            color: "white",
            padding: "0.3rem",
            backgroundColor: "#FF9933",
            ...(mobile && { fontSize: "20px" }),
          }}
        >
          Articles
        </h1>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {blogs &&
            blogs.map((blog) => (
              <Link
                to={`/blogs/${blog._id}`}
                key={blog.id}
                style={cardStyle}
                // onClick={() => handleBlogClick(blog.id)}
              >
                <img src={blog.img} alt={blog.title} style={imageStyle} />
                <h3>{blog.title}</h3>
              </Link>
            ))}
        </div>
        {selectedBlog && (
          <div style={blogStyle}>
            <div style={blogContentStyle}>
              <span style={closeStyle} onClick={handleCloseBlog}>
                Close
              </span>
              <h2>{selectedBlog.title}</h2>
              <img
                src={selectedBlog.image}
                alt={selectedBlog.title}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
              />
              <p style={contentStyle}>{selectedBlog.content}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BlogsComponent;
