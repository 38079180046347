import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  background-color: #fcf5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Title = styled.h1`
  font-size: 55px;
  margin-bottom: 20px;
  text-align: center;
  ${mobile({ fontSize: "30px" })}
`;

const FaqItem = styled.div`
  width: 50%;
  margin: 20px 0;
  border: 1px solid lightgray;
  padding: 20px;
  cursor: pointer;
  ${mobile({ width: "80%", padding: "10px", margin: "10px 0" })}
`;

const Question = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  ${mobile({ fontSize: "18px" })}
`;

const Answer = styled.div`
  font-size: 16px;
  ${mobile({ fontSize: "14px" })}
`;

const Faq = () => {
  const faqs = [
    {
      id: 1,
      question: "What is Rudraksha ?",
      answer:
        "Rudraksha beads are believed to have originated from Lord Shivas tears which fell on earth and transformed into trees. Rudraksha beads, botanically known as Elaeocarpus Ganitrus Roxb are the seeds of the Rudraksha fruit. The surface of Rudraksha beads features natural clefts known as Mukhis.For centuries, individuals have adorned themselves with Rudraksha beads to enhance their empowerment and even until today, the true extent of their powers are being explored.",
    },
    {
      id: 2,
      question: "Which is the best rudraksha for me ?",
      answer:
        "The best way to choose Rudraksha beads is by considering their properties and selecting the ones that closely match with your desire. It all depends on whether you wish to compliment or enhance the power of your existing Rudraksha mala by adding more beads. Additionally, seeking personalized recommendations from our experts via Whatsapp can assist in choosing the most suitable Rudraksha for your needs.",
    },
    {
      id: 3,
      question: "Who should wear Rudraksha ?",
      answer:
        "According to our scriptures, you can wear Rudraksha without any restrictions, however, as a precaution, you might consider the following rules :",
    },
    {
      id: 4,
      question:
        "What are the rules of wearing Rudraksha and how to maintain Rudraksha ?",
      answer:
        "According to our scriptures, you can wear Rudraksha without any restrictions, however, as a precaution, you might consider the following rules : Rudraksha beads are suitable for anyone, regardless of their gender, age, cultural heritage, ethnicity, or religious backgrounds. We recommend removing Rudraksha beads while consuming non-vegetarian food or alcohol and wearing them afterward. However, according to our scriptures, wearing them at all times without any restrictions is also permissible, as it ultimately depends on the wearer's choice. Rudraksha beads are believed to have solely positive effects on the wearer, without any negative effects.Rudraksha Can Be Removed During Women’s Monthly Cycle If They Wish To, But There Are No Such Restrictions Mentioned In Our Scriptures. We Suggest Women To Wear Rudraksha For As Long As Possible Without Any Hesitation.It is advisable to refrain from wearing the Rudraksha mala during bathing, as the soap water can cause dehydration of the beads. To maintain the cleanliness of your Rudraksha, it's recommended to wash it with water and a soft brush once every month or two. Additionally, applying oil to it can help keep it in good condition.",
    },
    {
      id: 5,
      question: "In case of an issue regarding my order ?",
      answer:
        "Feel free to reach out to us for any assistance with your order. Send us a message on Whatsapp, and we'll respond to your queries promptly. Alternatively, you can also email us at divyakashirudraksha.com for assistance with the same.",
    },
    {
      id: 6,
      question: "How do I begin wearing Rudraksha as a beginner ?",
      answer:
        "For those wearing Rudraksha for the first time we recommend connecting with our experts to know which Rudraksha will be good for you. You can start with one Rudraksha as a pendant or add multiple Rudraksha to your combination.",
    },
    // {
    //   id: 7,
    //   question: "Are there different types of Rudraksha beads ?",
    //   answer:
    //     "Yes, there are different types of Rudraksha beads based on the number of faces or mukhis they possess. Each type is associated with specific benefits and energies. Common types include 1 mukhi, 2 mukhi, 3 mukhi, and so on.",
    // },
    // {
    //   id: 8,
    //   question: "How can one identify genuine Rudraksha beads?",
    //   answer:
    //     "To identify genuine Rudraksha beads, one can look for certain characteristics such as a clear mukhi or face, natural contours, and a healthy shine. Consulting with an expert or purchasing from a trusted source is advisable.",
    // },
    // {
    //   id: 9,
    //   question: "Can Rudraksha beads be combined with other gemstones?",
    //   answer:
    //     "Yes, Rudraksha beads can be combined with other gemstones based on individual beliefs and astrological considerations. They are often strung together with other beads or worn alongside gemstone jewelry.",
    // },
    // {
    //   id: 10,
    //   question: "Can Rudraksha beads be re-energized or cleansed?",
    //   answer:
    //     "Rudraksha beads can be re-energized by soaking them in clean water overnight and chanting specific mantras. However, it is advisable to avoid exposing them to chemicals or excessive heat.",
    // },
  ];

  const [faqList, setFaqList] = useState(faqs);

  const handleToggle = (id) => {
    setFaqList((prevFaqList) =>
      prevFaqList.map((faq) =>
        faq.id === id ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  return (
    <Container>
      <Title>Frequently Asked Questions</Title>
      {faqList.map((faq) => (
        <FaqItem key={faq.id} onClick={() => handleToggle(faq.id)}>
          <Question>{faq.question}</Question>
          {faq.isOpen && (
            <Answer>
              {faq.id == 4 ? (
                <div>
                  <p style={{ fontWeight: "600" }}>
                    According to our scriptures, you can wear Rudraksha without
                    any restrictions, however, as a precaution, you might
                    consider the following rules :
                  </p>

                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <li>
                      Rudraksha beads are suitable for anyone, regardless of
                      their gender, age, cultural heritage, ethnicity, or
                      religious backgrounds.
                    </li>
                    <li>
                      We recommend removing Rudraksha beads while consuming
                      non-vegetarian food or alcohol and wearing them afterward.
                      However, according to our scriptures, wearing them at all
                      times without any restrictions is also permissible, as it
                      ultimately depends on the wearer's choice. Rudraksha beads
                      are believed to have solely positive effects on the
                      wearer, without any negative effects.
                    </li>
                    <li>
                      Rudraksha Can Be Removed During Women’s Monthly Cycle If
                      They Wish To, But There Are No Such Restrictions Mentioned
                      In Our Scriptures. We Suggest Women To Wear Rudraksha For
                      As Long As Possible Without Any Hesitation.
                    </li>
                    <li>
                      It is advisable to refrain from wearing the Rudraksha mala
                      during bathing, as the soap water can cause dehydration of
                      the beads.
                    </li>
                    <li>
                      To maintain the cleanliness of your Rudraksha, it's
                      recommended to wash it with water and a soft brush once
                      every month or two. Additionally, applying oil to it can
                      help keep it in good condition.
                    </li>
                  </ul>
                </div>
              ) : (
                faq.answer
              )}
            </Answer>
          )}
        </FaqItem>
      ))}
    </Container>
  );
};

export default Faq;

// import { Send } from "@material-ui/icons";
// import styled from "styled-components";
// import { mobile } from "../responsive";

// const Container = styled.div`
//   height: 60vh;
//   background-color: #fcf5f5;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
// `;
// const Title = styled.h1`
//   font-size: 70px;
//   margin-bottom: 20px;
// `;

// const Desc = styled.div`
//   font-size: 24px;
//   font-weight: 300;
//   margin-bottom: 20px;
//   ${mobile({ textAlign: "center" })}

// `;

// const InputContainer = styled.div`
//   width: 50%;
//   height: 40px;
//   background-color: white;
//   display: flex;
//   justify-content: space-between;
//   border: 1px solid lightgray;
//   ${mobile({ width: "80%" })}
// `;

// const Input = styled.input`
//   border: none;
//   flex: 8;
//   padding-left: 20px;
// `;

// const Button = styled.button`
//   flex: 1;
//   border: none;
//   background-color: #FF9933;
//   color: white;
// `;

// const Newsletter = () => {
//   return (
//     <Container>
//       <Title>Newsletter</Title>
//       <Desc>Get timely updates from your favorite products.</Desc>
//       <InputContainer>
//         <Input placeholder="Your email" />
//         <Button>
//           <Send />
//         </Button>
//       </InputContainer>
//     </Container>
//   );
// };

// export default Newsletter;
