import { createSlice } from "@reduxjs/toolkit"

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
    },
    reducers: {
        addProduct: (state, action) => {
            let check = true
            Array.from(state.products).map((ele) => {
                if (ele.title === action.payload.title) {
                    check = false;
                }
            })
            if (check) {
                state.products.push(action.payload);
                state.quantity += 1;
                state.total += action.payload.price * action.payload.quantity;
            }
            if (!check) alert("Item already in cart");

            else{
                window.alert("Item added to cart successfully")
            }
        },
        incQuantity: (state, action) => {
            console.log(action.payload)
            state.products[action.payload.index].quantity+=1;
            state.total += action.payload.price    
        },
        decQuantity: (state, action) => {
            console.log(action.payload) 
            if(state.products[action.payload.index].quantity==1){
                state.products.splice(action.payload.index,1)
                state.quantity -= 1;
            }
            else{
                state.products[action.payload.index].quantity-=1;
            }
            state.total -= action.payload.price
        },
    },

    // const quantity = useSelector(state => state.cart.quantity);

    // name:"data",
    // initialState:{
    //     address:"",
    //     mobile:"",
    //     // email:
    // },
    // reducers:{
    //     addBillingDetails: (state,action)=>{
    //         state.address = action.payload.billingAddress,
    //         state.mobile = action.payload.mobile
    //     },
    //     clearBillingDetails: (state,action)=>{
    //         state.address = "",
    //         state.mobile=""
    //     }
    // }
});


export const { addProduct, incQuantity, decQuantity } = cartSlice.actions;
export default cartSlice.reducer;