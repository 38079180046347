import React from "react";
import pc from "../../src/images/pc.jpeg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";

const Div = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  ${mobile({ flexDirection: "column", padding: "10px" })}
`;

const Div2 = styled.div`
  flex: 1;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 1rem 2rem;
`;

const H6 = styled.div`
  font-size: 20px;
  padding: 0.3rem 1rem;
  background-color: #ff9933;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  ${mobile({ padding: "10px", fontSize: "20px" })}
`;

const Image = styled.img`
  ${mobile({ padding: "10px" })}
`;

const Title = styled.div`
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 15px;
  border: 2px solid #ff9933;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;

  &:hover {
    background-color: #f8f4f4;
  }
`;

const Div3 = styled.div`
  flex: 1;
`;

const Consulation = () => {
  return (
    <div style={{ padding: "1rem 0" }}>
      <H6>Personal Consultation</H6>

      <Div>
        <Div2>
          <Title>
            {" "}
            Get Rudraksha Consultation for Free
            <br />
          </Title>
          “Discover Your Path with Ancient Wisdom”
          <br />
          <br />
          Get Rudraksha Consultation to Harness the Power of Rudraksha for
          Spiritual and Physical Well-being through Experienced Pandits from
          Kashi (Varanasi), Well-versed in Vedic Astrology and Rudraksha Science
          where you get Personalised Guidence through Deep Hororscope Analysis
          through Detailed Birth Chart Examination that help you Select
          Rudraksha Tailored to your Specific Needs and Goals.
          <br />
          <Link to="/consultation">
            <Button>Book Now</Button>
          </Link>
          <br />
          <br />
          For Enquiry You can whatsapp us on +91 7007303737
        </Div2>
        {/* <Div3>
          <Link to="/consultation">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image src={pc} alt="abc" />
            </div>
          </Link>
        </Div3> */}
      </Div>
    </div>
  );
};

export default Consulation;
