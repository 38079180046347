import React from "react";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import Footer from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0 10%;
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const Bold = styled.div`
  font-weight: bold;
`;

const Policy = () => {
  return (
    <Container>
      <Navbar />
      <div>
        <Div>
          <Title style={{ textAlign: "center" }}>Privacy Policy</Title>

          <Div1>
            <ul>
              {" "}
              <li>
                Who we are. Our website address is:
                <br />
                https://vishweshwararudraksha.com/
              </li>
              <li>
                What personal data we collect and why we collect it. We want to
                make you fully aware of certain data /information that we will
                be collecting from you so that you feel confident while sharing
                that information with us. The privacy policy covers the
                information Vishweshwara Rudraksha collect from user(s) of
                www.vishweshwararudraksha.com (“website”). By using the service
                on www.vishweshwararudraksha.com website, you agree to the
                collection and use of information in relation to this policy,
                terms of services, conditions, etc.
                <br /> Please note: - our privacy policy is subject to change
                from time to time without notice. To make sure you are aware of
                the changes, please review this policy periodically.
              </li>
            </ul>
          </Div1>

          <Div1>
            <p>1. Customer /User Data: -</p>
            <p>
              For an enhanced shopping experience, we may request specific
              identifiable information about you which may not be limited to
              your name, phone number, and postal address. this information is
              utilized for communication or identification proposes. the
              following outlines the types of data we may collect and their
              potential uses: -
            </p>

            <p>
              Personal details: - This will include you –
              <ul>
                <li>first and last name.</li>
                <li>email address </li>
                <li>contact number</li>
                <li> shipping and billing address </li>
                <li> pin code </li>
              </ul>
            </p>
          </Div1>

          <Div1>
            We collect this data when you create an account on our website, make
            a purchase or contact us.
          </Div1>

          <Div1>
            <Bold>How we use your personal data –</Bold>
            <p>
              In general, we use your personal information to improve our
              products and services, to communicate with you, to offer you
              personalized services and to amp up the security of our website.
            </p>

            {/* <ul>
              <li>Internal record keeping.</li>
              <li>
                We may use the information to improve our products and services.
              </li>
              <li>
                We may periodically send promotional emails about new products,
                special offers or other information which we think you may find
                interesting using the email address which you have provided.
              </li>
              <li>
                From time to time, we may also use your information to contact
                you for market research purposes. We may contact you by email,
                phone, fax or mail. We may use the information to customise the
                website according to your interests.
              </li>
            </ul> */}
            {/* <p>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure we have put in
              suitable measures.
            </p> */}
          </Div1>

          <Div1>
            <Bold>Improving our services –</Bold>
            <p>
              We may use your personal data to help us provide, improve, and
              develop our products, services and advertising. this includes
              using personal data for purposes such as data analysis, research
              and audits.
            </p>
          </Div1>
          <Div1>
            <Bold>Contacting You –</Bold>
            <p>
              Subject to your prior expressed consent for using our services, we
              may employ personal data to send you marketing communications
              regarding Vishweshwara Rudraksha – owned products and services,
              additionally we may use this information to communicate about your
              account or transactions and inform you about our policies and
              terms. if you prefer not to receive marketing email
              communications, feel free to contact us anytime to opt – out .
            </p>
          </Div1>
          <Div1>
            <Bold>For safety and security – </Bold>
            <p>
              We use personal data to verify accounts and users activity, as
              well as to promote safety and security, such as by monitoring
              fraud and investigating suspicious or potentially illegal
              activities or violations of our terms or policies.{" "}
            </p>
          </Div1>
          <Div1>
            <Bold>3. disclosure of users data: -</Bold>
            <p>
              We may employ third – party companies and individuals due to
              following reasons: -
            </p>

            <ul>
              <li>to facilitate our services;</li>

              <li>to provide the services on our behalf;</li>

              <li>to perform website related services; or </li>

              <li>To assist us in analyzing how our website is used.</li>
            </ul>

            <p>
              We want to inform our website users that these third parties have
              access to your personal information. the reason is to perform the
              task assigned to them on our behalf such as website hosting, email
              services, contests, marketing, sponsoring of sweepstakes, and
              other promotions, audacity, fulfilling customer orders, data
              analytics, providing customers services, and conducting customer
              research and satisfaction surveys.
            </p>

            <p>
              We may disclose personal information in accordance with any law or
              court order, or other legal process, as requested by a
              governmental or law or court order, or other legal process, as
              requested by a governmental or law enforcement authority. We may
              also disclose personal information to any of its sister concerns
              as when required, we may also disclose personal information when,
              in good faith we believe that its property or is otherwise
              necessary.
            </p>

            <p>
              If you believe that any information we are holding on you is
              incorrect or incomplete, please write to D 53/44 Luxa Road
              Varanasi UTTAR PRADESH 221010 . or contact us at or as soon as
              possible. We will promptly correct any information found to be
              incorrect.
            </p>

            <ul>
              <li>
                {" "}
                <Bold>Embedded content from other websites –</Bold> Articles on
                this site may include embedded content (e.g. video’s, images,
                articles, etc.). Embedded content from other websites behaves in
                the exact same way as if the visitor has visited the other
                website.
              </li>
              <li>
                {" "}
                <Bold>Security –</Bold> We value your trust in providing us your
                personal information, thus we are striving to use commercially
                acceptable means of protecting it. Once we receive your
                information, we utilize commercially reasonable procedures and
                security features to prevent unauthorized access.
              </li>
              <li>
                {" "}
                <Bold>Link to other sites –</Bold> We provide links to other
                websites for your convenience and information. These sites may
                have their own privacy policies in place. We are not responsible
                for the content of linked sites or any use of the sites. The
                privacy statement applies solely to data collected by us through
                our products and services. We encourage you to read the privacy
                policies of any third party before the proceedings to use their
                websites, products or services.
              </li>
              <li>
                {" "}
                <Bold>changes to privacy policy –</Bold>
                we may periodically change this privacy statement to keep pace
                with new technologies, industry practices, and regulatory
                requirements, among other reasons. we expect most such changes
                to be minor. any non material changes will take effect
                immediately upon posting of an updated privacy statement.{" "}
              </li>
              <li>
                {" "}
                <Bold>what rights you have over your data –</Bold> If you have
                an account on this site, or have left comments, you can request
                to receive an exported file of the personal data we hold about
                you, including any data you have provided to us. You can also
                request that we erase any personal data we hold about you. this
                does not include any data we are obliged to keep for
                administrative, legal, or security purposes. holding on you is
                incorrect or incomplete, possible, at
                vishweshwararudraksha@gmail.com. we will promptly correct any
                information found to be incorrect.
              </li>
              <li>
                {" "}
                <Bold>contact us –</Bold> If you have any questions or
                suggestions about our privacy policy, do not hesitate to contact
                us at vishweshwararudraksha@gmail.com.
              </li>
            </ul>
          </Div1>
        </Div>
      </div>

      <Footer />
    </Container>
  );
};

export default Policy;
