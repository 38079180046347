import styled from "styled-components";
import { categories } from "../data";
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
  ${mobile({ padding: "0px", flexDirection: "column", gap: "1.5rem", alignItems: "center" })}
`;

const Divv = styled.div`
  font-size: 2rem;
  margin:1rem 0;
  background-color: #FF9933;
  text-align: center;
  padding: 0.3rem 1rem;
  color: #FFFFFF;
  ${mobile({ fontSize: "20px", })}


`

const Categories = () => {
  return (
    <>
      <Divv>Categories: </Divv>
      <Container>
        {categories.map((item) => (
          <CategoryItem item={item} key={item.id} />
        ))}
      </Container>
    </>

  );
};

export default Categories;
