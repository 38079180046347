import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';


const UpperDiv = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
padding:40px;
${mobile({ padding: "20px", flexDirection: "column", gap: "1.5rem" })}
`

const Div = styled.div`
flex-basis:30%;
text-align:center;
`

const TestimonialSection = () => {
  return (
    <div style={{ background: '#f9f9f9' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '30px', backgroundColor: "#FF9933", padding: "0.3rem", color: "white" }}>Testimonials</h2>
      <UpperDiv>
        <Div>
          <img
            src="https://images.pexels.com/photos/16221482/pexels-photo-16221482/free-photo-of-smiling-man-posing-in-studio-wearing-suit.jpeg?auto=compress&cs=tinysrgb&w=800"
            alt="Testimonial 1"
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
          />
          <h4 style={{ margin: '0' }}>John Doe</h4>
          <p style={{ fontStyle: 'italic' }}>CEO, Example Inc.</p>
          <p style={{ lineHeight: '1.5' }}>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in felis eu elit interdum
            convallis ac in tortor. Morbi id ligula risus. Nulla interdum nisl a tempus tincidunt."
          </p>
        </Div>
        <Div >
          <img
            src="https://images.pexels.com/photos/4195342/pexels-photo-4195342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Testimonial 2"
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
          />
          <h4 style={{ margin: '0' }}>Jane Smith</h4>
          <p style={{ fontStyle: 'italic' }}>Designer, ABC Studio</p>
          <p style={{ lineHeight: '1.5' }}>
            "Ut luctus lacinia mauris, ac mattis velit faucibus non. Morbi sed nunc tincidunt, luctus orci
            non, mollis nibh. Aliquam posuere bibendum aliquet."
          </p>
        </Div>
        <Div>
          <img
            src="https://images.pexels.com/photos/3771839/pexels-photo-3771839.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Testimonial 3"
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
          />
          <h4 style={{ margin: '0' }}>Sarah Johnson</h4>
          <p style={{ fontStyle: 'italic' }}>Marketing Manager, XYZ Corporation</p>
          <p style={{ lineHeight: '1.5' }}>
            "Pellentesque malesuada eleifend eros, at consectetur massa tristique vitae. Fusce vestibulum,
            dui sit amet consectetur eleifend, ligula mauris ultricies tellus, non mattis lacus lectus sit amet
            justo."
          </p>
        </Div>
      </UpperDiv>
    </div>
  );
};

export default TestimonialSection;
