import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import Img2 from "../images/icons/legacy.jpeg";
import Img1 from "../images/icons/3.png";
import Img3 from "../images/icons/fair.jpeg";
import Img4 from "../images/icons/4.jpg";
import Img5 from "../images/icons/5.jpg";
import Img6 from "../images/icons/vedic.jpeg";

const UpperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  ${mobile({ padding: "20px", flexDirection: "column", gap: "1.5rem" })}
`;

const Div = styled.div`
  flex-basis: 30%;
  text-align: center;
`;

const WhyUsSection = () => {
  return (
    <div style={{ background: "#f9f9f9" }}>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px",
          backgroundColor: "#FF9933",
          padding: "0.3rem",
          color: "white",
        }}
      >
        Why Choose Us?
      </h2>
      <UpperDiv>
        <Div>
          <img
            src={Img2}
            alt="Quality"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>Over 60 Years of Legacy</h4>
        </Div>
        <Div>
          <img
            src={Img1}
            alt="Benefits"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>Lab Certified Products</h4>
        </Div>
        <Div>
          <img
            src={Img3}
            alt="Versatile"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>Fair and Reasonable Rates</h4>
        </Div>
      </UpperDiv>

      <br /> 

      <UpperDiv>
        <Div>
          <img
            src={Img5}
            alt="Quality"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>Expert Advice and Counselling</h4>
        </Div>
        <Div>
          <img
            src={Img4}
            alt="Benefits"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>World wide distribution</h4>
        </Div>
        <Div>
          <img
            src={Img6}
            alt="Versatile"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h4 style={{ margin: "0" }}>Vedic Energization</h4>
        </Div>
      </UpperDiv>
    </div>
  );
};

export default WhyUsSection;
