import { Badge } from "@material-ui/core";
import { Search, ShoppingCartOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import logo1 from ".././images/logo1.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/userRedux";



const Container = styled.div`
  height: 60px;
  ${mobile({ height: "50px" })}
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: "10px 0px"})}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;


  ${mobile({ display: "none" })}


`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
  ${mobile({ display: "none" })}
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
`;

const Input = styled.input`
  border: none;
  ${mobile({ width: "50px" })}
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;

const Logo = styled.h1`
  font-weight: bold;
  ${mobile({ fontSize: "24px" })}
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const Navbar = () => {

  const quantity = useSelector((state) => state.cart.quantity);
  const isUserLoggedIn = false;
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();


  const { currentUser, isFetching, error } = useSelector((state) => state.user);

  useEffect(() => {
    setUserData(currentUser);
  });



    const callCategory = (cat) => {
    setSelected(cat);
    navigate(`/products/${cat}`);
  };

  const handleLogout = ()=>{
    dispatch(logoutUser());
    window.location.reload();

  }
  return (
    <Container>
      <Wrapper>
        <Left>
        <div style={{ display: "flex", gap: "1rem" }}>
             <select
              style={{ padding: "5px 10px" }}
              value={selected}
              onChange={(e) => callCategory(e.target.value)}
            >
              <option>Categories</option>
              <option value="loose_beads">Ruraksha</option>
              <option value="kavach_kanthas">Rudraksha Combinations</option>
              <option value="malas">Malas</option>
              <option value="shivling_shaligram">Shivling & Shaligrams</option>
              <option value="brass_figures">Brass Idds & Figures</option>
              <option value="pooja">Yantras, Shankhs & Pooja Articles</option>
            </select>
          </div>

        </Left>
        <Center>
          <Logo>
            <Link to="/">
            <img src={logo1} style={{height:"50px"}}></img>
            </Link>
          </Logo>
        </Center>
        <Right>
          {userData && userData.email ? (
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {userData.email}

                <button onClick={handleLogout}>Logout</button>
              </div>
            </>
          ) : (
            <>
              <Link to="/login">
                <MenuItem>SIGN IN</MenuItem>
              </Link>
            </>
          )}
         
          <Link to="/cart">
            <MenuItem>
              <Badge badgeContent={4} color="primary">
                <ShoppingCartOutlined />
              </Badge>
            </MenuItem>
          </Link>


        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;

















// import { Badge } from "@material-ui/core";
// import { Category, Search, ShoppingCartOutlined } from "@material-ui/icons";
// import React from "react";
// import styled from "styled-components";
// import { mobile } from "../responsive";
// import { useSelector } from "react-redux";
// import { Link, Router } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";
// import logo1 from ".././images/logo1.png";

// import logo from "../../src/images/logo.png";

// const Container = styled.div`
//   height: 60px;
//   ${mobile({ height: "auto", display: "flex", flexDirection: "column" })}
// `;

// const Wrapper = styled.div`
//   padding: 10px 20px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   ${mobile({ padding: "10px 0px" })}
// `;
// const Wrappper = styled.div`
//   display: none;
//   padding: 10px 5px;
//   ${mobile({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-around",
//   })}
// `;

// const Left = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   ${mobile({ flexDirection: "column", gap: "1rem" })}
// `;

// const Language = styled.span`
//   font-size: 14px;
//   cursor: pointer;
//   ${mobile({ display: "none" })}
// `;

// const SearchContainer = styled.div`
//   border: 0.5px solid lightgray;
//   display: flex;
//   align-items: center;
//   margin-left: 25px;
//   padding: 5px;
//   ${mobile({ display: "none" })}
// `;

// const Input = styled.input`
//   border: none;
//   ${mobile({ width: "50px" })}
// `;

// const Center = styled.div`
//   flex: 1;
//   text-align: center;
// `;

// const Logo = styled.h1`
//   font-weight: bold;
//   ${mobile({ fontSize: "24px" })}
// `;
// const Right = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   ${mobile({ flex: 2, marginRight: "1rem" })}
// `;

// const MenuItem = styled.div`
//   font-size: 14px;
//   cursor: pointer;
//   margin-left: 25px;
//   ${mobile({ fontSize: "12px", marginLeft: "10px" })}
// `;

// const Blog1 = styled.span`
//   ${mobile({ display: "none" })}
// `;

// const Blog2 = styled.span`
//   display: none;
// `;

// const Divvv = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 1rem;
//   gap: 1rem;
//   ${mobile({ display: "none" })}
// `;

// const Navbar = () => {
//   const quantity = useSelector((state) => state.cart.quantity);
//   const isUserLoggedIn = false;
//   // const user = useSelector((state) => state.user.currentUser);
//   // console.log(user);
//   const navigate = useNavigate();
//   const [selected, setSelected] = useState("");
//   const [userData, setUserData] = useState();

//   const { currentUser, isFetching, error } = useSelector((state) => state.user);

//   useEffect(() => {
//     console.log(currentUser);
//     setUserData(currentUser);
//   });

//   // const getUser = async () => {
//   //   try {
//   //     const res = await axios.get(
//   //       "https://api-ecommerce-bay.vercel.app/login/success",
//   //       {
//   //         headers: {
//   //           withCredentials: true,
//   //         },
//   //       }
//   //     );
//   //     console.log("Login verified");
//   //     // console.log(res.data);
//   //     setUserData(res.data.user);
//   //     console.log(res.data.user);
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // };

//   // const handleLogout = async () => {
//   //   window.open("https://api-ecommerce-bay.vercel.app/logout", "_self");
//   // };

//   // useEffect(() => {
//   // console.log("YESSSSSSSSSSSSSSSSSSSSSs");
//   // }, []);

//   const callCategory = (cat) => {
//     setSelected(cat);
//     navigate(`/products/${cat}`);
//   };
//   return (
//     <Container>
//       <Wrappper>
//         <Link to= "/orders" style={{ textDecoration: "none" }}>
//           Orders
//         </Link>
//         <Link to="/products/Rudraksha" style={{ textDecoration: "none" }}>
//           <div
//             style={{
//               width: "100px",
//               backgroundColor: "#FF9933",
//               color: "white",
//               padding: "5px 10px",
//               borderRadius: "10px",
//               cursor: "pointer",
//               textAlign: "center",
//               fontSize: "12px",
//             }}
//           >
//             Rudraksha
//           </div>
//         </Link>
//         <Link to="/products/pooja" style={{ textDecoration: "none" }}>
//           <div
//             style={{
//               width: "80px",
//               backgroundColor: "#FF9933",
//               color: "white",
//               padding: "5px 10px",
//               borderRadius: "10px",
//               cursor: "pointer",
//               textAlign: "center",
//               fontSize: "12px",
//             }}
//           >
//             Pooja Services
//           </div>
//         </Link>
//         <Link to="/products/kavach_kanthas" style={{ textDecoration: "none" }}>
//           <div
//             style={{
//               width: "80px",
//               backgroundColor: "#FF9933",
//               color: "white",
//               padding: "5px 10px",
//               borderRadius: "10px",
//               cursor: "pointer",
//               textAlign: "center",
//               fontSize: "12px",
//             }}
//           >
//             Rudraksha Combinations
//           </div>
//         </Link>
//       </Wrappper>

//       <Wrapper>
//         <Left>
//           <Blog2>
//             <Link to="/blogs" style={{ textDecoration: "none",marginRight: "2rem",
//                   fontSize: "2rem",
//                   cursor: "pointer", }}>
              
//                 Articles
//             </Link>
//           </Blog2>
//           <Link to="/">
//           <img style={{width:"100px",height:"100px"}} src={logo1}></img>
//           </Link>

//           <div style={{ display: "flex", gap: "1rem" }}>
//             {/* <img src={logo1}></img> */}
//             <label style={{ marginLeft: "1rem",width:"30px",height:"30px",  display:"flex",alignItems:"center"}}>
//             </label>
//             <select
//               style={{ padding: "5px 10px" }}
//               value={selected}
//               onChange={(e) => callCategory(e.target.value)}
//             >
//               <option>Categories</option>
//               <option value="loose_beads">Ruraksha</option>
//               <option value="kavach_kanthas">Rudraksha Combinations</option>
//               <option value="malas">Malas</option>
//               <option value="shivling_shaligram">Shivling & Shaligrams</option>
//               <option value="brass_figures">Brass Idds & Figures</option>
//               <option value="pooja">Yantras, Shankhs & Pooja Articles</option>
//             </select>
//           </div>

//           <Divvv>
//             <Link to="/products/rudraksha" style={{ textDecoration: "none" }}>
//               <div
//                 style={{
//                   width: "150px",
//                   backgroundColor: "#FF9933",
//                   color: "white",
//                   padding: "5px 10px",
//                   borderRadius: "10px",
//                   cursor: "pointer",
//                   textAlign: "center",
//                 }}
//               >
//                 Rudraksha
//               </div>
//             </Link>
//             <Link to="/products/pooja" style={{ textDecoration: "none" }}>
//               <div
//                 style={{
//                   width: "150px",
//                   backgroundColor: "#FF9933",
//                   color: "white",
//                   padding: "5px 10px",
//                   borderRadius: "10px",
//                   cursor: "pointer",
//                   textAlign: "center",
//                 }}
//               >
//                 Pooja Services
//               </div>
//             </Link>
//             <Link
//               to="/products/kavach_kanthas"
//               style={{ textDecoration: "none" }}
//             >
//               <div
//                 style={{
//                   width: "150px",
//                   backgroundColor: "#FF9933",
//                   color: "white",
//                   padding: "5px 10px",
//                   borderRadius: "10px",
//                   cursor: "pointer",
//                   textAlign: "center",
//                 }}
//               >
//                 Rudraksha Combinations
//               </div>
//             </Link>
//           </Divvv>
//         </Left>
//         {/* <Center>
//           <Logo>Logo</Logo>
//         </Center> */}

//         <Right>
//           <Blog1>
//             <Link to="/blogs">
//               <a
//                 style={{
//                   marginRight: "2rem",
//                   fontSize: "2rem",
//                   cursor: "pointer",
//                   textDecoration:"none"
//                 }}
//               >
//                 Articles
//               </a>
//             </Link>
//           </Blog1>
//           {userData && userData.email ? (
//             <>
//               <div
//                 style={{ display: "flex", alignItems: "center", gap: "10px" }}
//               >
//                 {userData.email}

//                 {/* <button onClick={handleLogout}>Logout</button> */}
//               </div>
//             </>
//           ) : (
//             <>
//               <Link to="/login">
//                 <MenuItem>SIGN IN</MenuItem>
//               </Link>
//             </>
//           )}

//           <Link to="/cart">
//             <MenuItem>
//               <Badge badgeContent={quantity} color="primary">
//                 <ShoppingCartOutlined />
//               </Badge>
//             </MenuItem>
//           </Link>
//         </Right>
//       </Wrapper>
//     </Container>
//   );
// };

// export default Navbar;
